import type { FormEvent } from 'react';
import { useState } from 'react';

import { z } from 'zod';

import { withQuery } from '@/components/query/withQuery';
import Button from '@/components/ui/buttons/Button';
import { Input } from '@/components/ui/shadcn/input';
import { Label } from '@/components/ui/shadcn/label';

import { useLogin } from '@/hooks/useLogin';

const loginSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters long'),
});

function LoginForm() {
  const login = useLogin();
  const [validationErrors, setValidationErrors] = useState<z.ZodError | null>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidationErrors(null);
    const formData = new FormData(e.currentTarget);
    const parsedData = loginSchema.safeParse({
      email: formData.get('email') as string,
      password: formData.get('password') as string,
    });

    if (!parsedData.success) {
      setValidationErrors(parsedData.error);
      return;
    }

    login.mutate(
      {
        email: parsedData.data.email,
        password: parsedData.data.password,
      },
      {
        onError: (error) => {
          // @ts-ignore
          const errorMessage = error.response?.data?.error || error.message;
          error.message = errorMessage;
        },
        onSuccess: () => {
          window.location.href = '/my-account';
        },
      },
    );
  };

  return (
    <div className='flex flex-col gap-9'>
      <div>
        <h2 className='font-robotoCondensed text-3xl font-semibold uppercase text-neutral-950'>Sign In</h2>
        <p className='mt-2 font-acuminPro text-base font-medium leading-normal text-neutral-800'>
          Sign in to access your Hook & Barrel subscription
        </p>
      </div>

      <form onSubmit={handleSubmit} className='flex flex-col gap-6'>
        <div className='flex flex-col gap-1'>
          <Label htmlFor='email' weight='bold' size='lg'>
            Email
          </Label>
          <Input
            id='email'
            type='email'
            name='email'
            required
            placeholder='Enter your email address'
            errorMessage={validationErrors?.errors.find((err) => err.path[0] === 'email')?.message}
            className='!normal-case'
          />
        </div>

        <div className='flex flex-col gap-1'>
          <Label htmlFor='password' weight='bold' size='lg'>
            Password
          </Label>
          <Input
            id='password'
            type='password'
            name='password'
            required
            placeholder='Enter your password'
            errorMessage={validationErrors?.errors.find((err) => err.path[0] === 'password')?.message}
            className='!normal-case'
          />
        </div>

        <div className='flex flex-col gap-4'>
          <Button type='submit' disabled={login.isPending} className='w-full'>
            {login.isPending ? 'Signing in...' : 'Sign In'}
          </Button>

          {login.isError && (
            <div className='text-red-500 text-sm font-acuminPro text-center'>{login.error.message}</div>
          )}
        </div>

        <div className='flex flex-col items-center gap-2 text-center font-acuminPro font-medium'>
          <a href='/forgot-password' className='text-sm text-neutral-600 hover:text-neutral-900'>
            Forgot your password?
          </a>
          <div className='text-sm text-neutral-600'>
            Don't have a subscription?{' '}
            <a href='/subscribe' className='text-hb-orange hover:text-neutral-900'>
              Get One
            </a>
          </div>
        </div>
      </form>
    </div>
  );
}

export default withQuery(LoginForm);
