import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const labelVariants = cva(
  'text-sm font-medium leading-snug peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-acuminPro uppercase',
  {
    variants: {
      size: {
        default: 'text-sm',
        sm: 'text-xs',
        lg: 'text-base',
      },
      weight: {
        default: 'font-medium',
        normal: 'font-normal',
        semibold: 'font-semibold',
        bold: 'font-bold',
      },
      color: {
        default: 'text-neutral-950',
        muted: 'text-neutral-500',
      },
    },
    defaultVariants: {
      size: 'default',
      weight: 'default',
      color: 'default',
    },
  },
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, color, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ color }), className)} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
